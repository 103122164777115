import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../services/store";
import {
  Button,
  Spin,
  Typography,
  Row,
  Col,
  Card,
  Modal,
  Collapse,
  Avatar,
  Tag,
  Flex,
} from "antd";
import { CopyOutlined, QuestionCircleTwoTone } from "@ant-design/icons";
import PartnerList from "../components/PartnerList";
import { fetchPartners } from "../helpers/api";
import { Partner } from "../types";
import WebApp from "@twa-dev/sdk";

const { Paragraph, Title, Text, Link } = Typography;
const { Panel } = Collapse;
const { Meta } = Card;

const Partners: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData } = useSelector((state: RootState) => state.user);
  const [partners, setPartners] = useState<Partner[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalPartners, setTotalPartners] = useState<number>(0);
  const [totalAllPartners, setTotalAllPartners] = useState<number>(0);
  const [totalPartnersSubscribed, setTotalPartnersSubscribed] =
    useState<number>(0);
  const [partnerLevel, setPartnerLevel] = useState<number>(0);
  const [partnerBonusLevel, setPartnerBonusLevel] = useState<number>(0);
  const [isPartnerVIP, setIsPartnerVIP] = useState<number>(0);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isLinkVisible, setIsLinkVisible] = useState<boolean>(false);
  const [seniorPartner, setSeniorPartner] = useState<{
    user_id: number;
    first_name: string;
    last_name: string;
    username: string;
  } | null>(null);
  const itemsPerPage = 10;

  useEffect(() => {
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(() => window.history.back());

    return () => {
      WebApp.BackButton.hide();
    };
  }, []);

  useEffect(() => {
    setLoading(true);

    fetchPartners(page, itemsPerPage).then((data) => {
      setPartners(data.partners);
      setTotalPages(data.totalPages);
      setTotalPartners(data.totalPartners);
      setTotalAllPartners(data.totalAllPartners);
      setSeniorPartner(data.seniorPartner);
      setPartnerLevel(data.partnerLevel);
      setPartnerBonusLevel(data.partnerBonusLevel);
      setIsPartnerVIP(data.vipStatus);
      setTotalPartnersSubscribed(data.subscribedReferralsCount);
    });
    setLoading(false);
  }, [userData, page]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const faqItems = [
    {
      key: "1",
      label: t("Partners.Question1.label"),
      content: t("Partners.Question1.content"),
    },
    {
      key: "2",
      label: t("Partners.Question2.label"),
      content: (
        <span
          dangerouslySetInnerHTML={{
            __html: t("Partners.Question2.content"),
          }}
        />
      ),
    },
    {
      key: "3",
      label: t("Partners.Question3.label"),
      content: (
        <span
          dangerouslySetInnerHTML={{
            __html: t("Partners.Question3.content"),
          }}
        />
      ),
    },
    {
      key: "4",
      label: t("Partners.Question4.label"),
      content: (
        <span
          dangerouslySetInnerHTML={{
            __html: t("Partners.Question4.content"),
          }}
        />
      ),
    },
    {
      key: "5",
      label: t("Partners.Question5.label"),
      content: (
        <span
          dangerouslySetInnerHTML={{
            __html: t("Partners.Question5.content"),
          }}
        />
      ),
    },
  ];

  const modalContent = (
    <Collapse accordion>
      {faqItems.map((faq) => (
        <Panel key={faq.key} header={faq.label}>
          <p>{faq.content}</p>
        </Panel>
      ))}
    </Collapse>
  );

  return (
    <div style={{ padding: "20px" }}>
      <div className="faq_page" onClick={() => setIsModalVisible(true)}>
      <Text>F.A.Q</Text>
      <Button
        type="link"
        icon={<QuestionCircleTwoTone />}
      />
      </div>
      <Title level={3} className="title_page">{t("Partners")}</Title>
      <Button
        type="primary"
        onClick={() => navigate("/partners/statistics")}
        style={{ marginBottom: "20px" }}
      >
        {t("Partners statistics")}
      </Button>
      <Button
        type="primary"
        icon={<CopyOutlined />}
        onClick={() => setIsLinkVisible(!isLinkVisible)}
        style={{ marginBottom: "20px" }}
      >
        {t("Show the referral link")}
      </Button>
      {isLinkVisible && (
        <Paragraph
          copyable
        >{`https://t.me/Webtransfer_apps_bot/webtransfer?startapp=${
          userData!.id
        }`}</Paragraph>
      )}
      {loading ? (
        <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
          <Spin size="large" />
        </Row>
      ) : (
        <>
          {seniorPartner && (
            <div
              className="partners-level"
              onClick={() => navigate(`/partners/${seniorPartner.user_id}`)}
            >
              <Text>{t("Your senior partner")} :</Text>
              <Tag color="#007aff">{`${seniorPartner.first_name} ${seniorPartner.last_name}`}</Tag>
            </div>
          )}

          {/* <div className="partners-level">
            <Text>{t("Available levels")} :</Text>
            <Tag color="#007aff">{isPartnerVIP ? "PREMIUM" : partnerLevel}</Tag>
          </div> */}
          <div className="partners-level">
            <Text>{t("Available bonus levels")} :</Text>
            <Tag color="#007aff" onClick={() => navigate("/invest/marketing")}>{partnerBonusLevel}</Tag>
          </div>
          <Row gutter={16}>
            <Col span={24} style={{ marginTop: 15, marginBottom: 15 }}>
              <Card title={t("Total Partners")} size="small">
                <div className="history-item-price">
                  <Text strong>
                    {totalPartners} / {totalAllPartners}
                  </Text>
                  <Text type="secondary" style={{ fontSize: 12 }}>
                    {t("Total subscribed")}: {totalPartnersSubscribed}
                  </Text>
                </div>
              </Card>
            </Col>
            <Col span={24}>
              <Card title={t("Partner Balance")} size="small" className="partners_balance">
                <Text strong>${userData?.partner_balance}</Text>
                <Tag color="#007aff" style={{marginLeft: 10}} onClick={() => navigate("/invest-partners-balance")}>{t("Invest")}</Tag>
              </Card>
            </Col>
          </Row>
          <PartnerList
            partners={partners}
            page={page}
            totalPages={totalPages}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
          />
        </>
      )}
      <Modal
        title={t("FAQ")}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        {modalContent}
      </Modal>
    </div>
  );
};

export default Partners;
