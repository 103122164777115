import { beginCell, Address, toNano } from "@ton/ton";
import CryptoJS from "crypto-js";

export async function sendTransaction(
  comment: string,
  tokenType: string,
  walletAddress: string | null,
  tonAddress: string,
  amount: number | null
) {
  let myTransaction;
  const secretKey = CryptoJS.SHA256("webtransfer").toString(CryptoJS.enc.Hex);
  const key = CryptoJS.enc.Hex.parse(secretKey);
  const iv = CryptoJS.lib.WordArray.random(16);

  const encrypted = CryptoJS.AES.encrypt(comment, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  const encryptedComment = iv
    .concat(encrypted.ciphertext)
    .toString(CryptoJS.enc.Base64);

  if (tokenType === "USDT") {
    if (walletAddress) {
      const forwardPayload = beginCell()
        .storeUint(0, 32)
        .storeStringTail(encryptedComment.toString())
        .endCell();

      const body = beginCell()
        .storeUint(0xf8a7ea5, 32) // jetton transfer op code
        .storeUint(0, 64) // query_id:uint64
        .storeCoins(toNano(amount! / 1000)) // amount:(VarUInteger 16) -  Jetton amount for transfer (decimals = 6 - jUSDT, 9 - default)
        .storeAddress(
          Address.parse(tonAddress)
        ) // destination:MsgAddress
        .storeAddress(Address.parse(walletAddress)) // response_destination:MsgAddress
        .storeUint(0, 1) // custom_payload:(Maybe ^Cell)
        .storeCoins(toNano(0.01)) // forward_ton_amount:(VarUInteger 16) - if >0, will send notification message
        .storeBit(1) // we store forwardPayload as a reference
        .storeRef(forwardPayload)
        .endCell();

      myTransaction = {
        validUntil: Math.floor(Date.now() / 1000) + 360,
        messages: [
          {
            address: walletAddress, // sender jetton wallet
            amount: toNano(0.02).toString(), // for commission fees, excess will be returned
            payload: body.toBoc().toString("base64"), // payload with jetton transfer body
          },
        ],
      };
    } else {
      console.error("Failed to retrieve Jetton Wallet Address.");
    }
  } else if (tokenType === "TON") {
    const body = beginCell()
      .storeUint(0, 32)
      .storeStringTail(encryptedComment.toString())
      .endCell();

    myTransaction = {
      validUntil: Math.floor(Date.now() / 1000) + 60,
      messages: [
        {
          address: tonAddress,
          amount: toNano(amount!).toString(),
          payload: body.toBoc().toString("base64"),
        },
      ],
    };
  }
  return myTransaction;
}
