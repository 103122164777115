import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Progress, Card, Typography, Row, Col, Statistic, Spin } from "antd";
import WebApp from "@twa-dev/sdk";
import axios from "axios";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";
import { useSelector } from "react-redux";
import { RootState } from "../services/store";
import { UserOutlined, UsergroupAddOutlined } from "@ant-design/icons";
const { Title, Text } = Typography;

const Statistics: FC = () => {
  const { t } = useTranslation();
  const { userData } = useSelector((state: RootState) => state.user);
  const { tonPrice } = useSelector((state: RootState) => state.ton);
  const [creditRating, setCreditRating] = useState<number>(0);
  const [activeLoans, setActiveLoans] = useState<number>(0);
  const [totalVolume, setTotalVolume] = useState<number>(0);
  const [totalVolumeUSDT, setTotalVolumeUSDT] = useState<number>(0);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [newUsers, setNewUsers] = useState<number>(0);
  const [webCredits, setWebCredits] = useState<number>(0);
  const [totalActiveLoansUSDT, setTotalActiveLoansUSDT] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [ratesData, setRatesData] = useState<{ rate: number; count: number }[]>(
    []
  );

  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#A569BD",
    "#48C9B0",
    "#F4D03F",
    "#EB984E",
    "#5DADE2",
    "#58D68D",
    "#AF7AC5",
    "#85C1E9",
    "#F5B7B1",
    "#A9CCE3",
    "#F7DC6F",
    "#E74C3C",
    "#D2B4DE",
    "#1ABC9C",
    "#F8C471",
    "#C0392B",
  ];

  useEffect(() => {
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(() => window.history.back());

    return () => {
      WebApp.BackButton.hide();
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    axios
      .post("/api/get-statistics.php")
      .then((response) => {
        setCreditRating(response.data.creditRating.toFixed(0));
        setRatesData(response.data.rates);
        setActiveLoans(response.data.active_loans);
        setTotalUsers(response.data.total_users);
        setNewUsers(response.data.new_users_today);
        setWebCredits(response.data.total_web_tokens);
        setTotalVolume(response.data.turnover.total_amount);
        setTotalVolumeUSDT(response.data.turnover.total_amount_usdt);
        if(tonPrice){
          setTotalActiveLoansUSDT( response.data.totalActiveLoansUSDT + (response.data.totalActiveLoansTON * tonPrice))
        }
      })
      .catch((error) => {
        console.error("Error fetching statistics:", error);
      });
      setIsLoading(false);
  }, [userData]);

  if (isLoading) {
    return (
      <div className="loader-container">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{ padding: "20px" }}>
      <Title level={3}>{t("statistics")}</Title>
      <Card
        title={t("your credit rating")}
        bordered={false}
        style={{ marginBottom: "20px" }}
      >
        <Flex gap="small" align="center" justify="center" wrap>
          <Progress type="circle" percent={creditRating} size={80} />
        </Flex>
      </Card>
      <Card title={t("percentage rates in the last 24 hours")} bordered={false}>
        <div style={{ width: "100%", height: 300 }}>
          <ResponsiveContainer>
            <PieChart width={100} height={150}>
              <Pie
                data={ratesData}
                labelLine={false}
                label={({ percent }) => `${(percent * 100).toFixed(1)}%`}
                outerRadius={80}
                paddingAngle={5}
                fill="#8884d8"
                nameKey="rate"
                dataKey="count"
              >
                {ratesData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </Card>
      <Card title={t("General statistics")} style={{ marginTop: 20 }}>
        <Row gutter={16}>
          <Col span={24} style={{ marginTop: 10 }}>
            <Statistic title={t("Total loans")} value={activeLoans} prefix="$" />
          </Col>
          <Col span={24} style={{ marginTop: 10 }}>
            <Statistic title={t("Total users")} value={totalUsers} prefix={<UserOutlined />} />
          </Col>
          <Col span={24} style={{ marginTop: 10 }}>
            <Statistic title={t("New users")} value={newUsers} prefix={<UsergroupAddOutlined />} />
          </Col>
          <Col span={24} style={{ marginTop: 10 }}>
            <Statistic title={t("Total web credits")} value={webCredits} />
          </Col>
          <Text type="secondary" style={{ marginTop: 20 }}>{t("Turnover (volume) of loans issued and received")}</Text>
          <Col span={24} style={{ marginTop: 10 }}>
            <Statistic value={totalVolume} suffix="TON" precision={0}/>
            <Statistic value={totalVolumeUSDT} suffix="USDT" precision={0}/>
          </Col>
        </Row>
      </Card>

      <Card title={t("Self statistics")} style={{ marginTop: 20 }}>
        <Row gutter={16}>
          <Col span={24} style={{ marginTop: 10 }}>
            <Statistic title={t("Total active loans")} value={totalActiveLoansUSDT} prefix="$" />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Statistics;
